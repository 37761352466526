import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarker, faPhone, faEnvelope, faGlobe } from "@fortawesome/free-solid-svg-icons";

library.add(faMapMarker, faPhone, faEnvelope, faGlobe)

export default class Contact extends Component {

  render() {
    return (
      <Col sm={12}>
        <div className="contact-two-top row">
            <div className="contact-info row">

              <Col sm={12}>
                <h3>Contacta con nosotras</h3>
              </Col>
              <Col sm={6}>
                 <div className="contact-info-box">
                   <div className="icon"><FontAwesomeIcon icon={faPhone} /></div>
                   <div className="cinfo">
                   <h4>Teléfono</h4>
                   <p>+34 624 28 63 63</p>
                   <p>9 a 14h de lunes a viernes</p>
                   </div>
                 </div>
               </Col>
              <Col sm={6}>
              <div className="contact-info-box">
                <div className="icon"><FontAwesomeIcon icon={faEnvelope} /></div>
                <div className="cinfo">
                <h4>Email</h4>
                <p>info@candelacoop.com</p>
                </div>
              </div>
              </Col>

              <Col sm={6}>
              <div className="contact-info-box">
                <div className="icon"><FontAwesomeIcon icon={faGlobe} /></div>
                <div className="cinfo">
                <h4>Web</h4>
                <p>https://candelacoop.com</p>
                </div>
              </div>
              </Col>

            </div>
          {/* </Col> */}
        </div>
      </Col>
    )
  }
}
