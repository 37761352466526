import React from 'react';
import { Container, Image, Row, Col } from 'react-bootstrap';
import Header from '../components/Header';
import TitleBreadcrumbs from '../components/TitleBreadcrumbs';
import Footer from '../components/Footer';
import LegalPartnerForm from '../components/LegalPartnerForm';
import NaturalPartnerForm from '../components/NaturalPartnerForm';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faTwitter, faTelegramPlane, faInstagram } from '@fortawesome/free-brands-svg-icons';

import partner01 from '../../assets/images/light-bulb.jpg';
import '../../assets/css/about.css';

library.add(faAngleUp, faFacebookF, faTwitter, faTelegramPlane, faInstagram);


function Partner(props) {
  const mode = props.mode;

  document.title = `${props.name} | CandelaCoop`;

  return (
    <div className="About">
      <Header />
      <TitleBreadcrumbs name={props.name} />

      <section className="about-ds">
        <Container>
          <Row>
            <Col md={7} sm={6} className="about-ds-content">
              <div className="section-header03">
                <h2>Forma parte de Candela</h2>

                <p>Entonces, ¿te apuntas?</p>
                <p>
                  Aquí puedes unirte a la comunidad de combatientes energéticas de Andalucía y formar parte de una
                  cooperativa que lucha por un modelo energético sostenible, justo y democrático.
                </p>
                <p>
                  Nos encanta que estés dispuesta a crear comunidad y a aportar cordura a la producción y al uso de la
                  energía en nuestra tierra.
                </p>

								<p>
                  Actualmente comercializamos mediante un acuerdo con Som Energía y trabajamos en proyectos de
		  autogeneración de energía verde (autoconsumo y comunidades energéticas) para que las socias y
		  socios de Candela podamos abandonar las formas convencionales de consumo de electricidad y
		  dejar de financiar a través de nuestra factura a las empresas del oligopolio eléctrico.
		  Al hacerte socia recibirás el formulario para proceder a la contratación de tu electricidad.
                </p>
                <p>
                  Puedes hacerte socia rellenando el formulario que encontrarás abajo y, si te apetece, puedes
                  incorporarte a uno de nuestros grupos de trabajo y participar en la puesta en marcha de los diferentes
                  proyectos de la cooperativa.
                </p>
                <p>
                  Recuerda que en esta misma web puedes consultar los <a href="/documentos/" title="Estatutos">Estatutos</a> y
                  el <a href="/documentos/" title="Código ético">Código ético</a> que rigen la cooperativa, nos gusta que estés
                  informada y conozcas bien todos los detalles.
                </p>
                <p>
                  ¡Venga, nuestras puertas están abiertas!
                </p>
                <p>
                  ¡Contacta con nosotras!
                </p>

              </div>

              <p>Para conocer todo lo que vamos haciendo, síguenos en redes sociales:</p>
              <div>
                  <a className="rrss-inline" target="_blank" rel="noreferrer" href="https://twitter.com/CandelaCoop"><FontAwesomeIcon icon={faTwitter} /></a>
                  <a className="rrss-inline" target="_blank" rel="noreferrer" href="https://facebook.com/candelacoop"><FontAwesomeIcon icon={faFacebookF} /></a>
                  <a className="rrss-inline" target="_blank" rel="noreferrer" href="https://t.me/candelacoop"><FontAwesomeIcon icon={faTelegramPlane} /></a>
                  <a className="rrss-inline" target="_blank" rel="noreferrer" href="https://www.instagram.com/candelasociedadcooperativa/"><FontAwesomeIcon icon={faInstagram} /></a>
              </div>
            </Col>

            <Col md={5} sm={6} className="about-ds-image">
              <Image src={partner01} alt="" thumbnail />
            </Col>
          </Row>
        </Container>
      </section>

      <section className="member-forms">
        <Container>
          <div className="section-header03">
            <h2>Inscripción</h2>
            <Row className="form-group">
              <Col sm={12}>
                <p>
                El procedimiento para hacerte socia/o es muy sencillo. Estos son los pasos:
                </p>
                <ol>
                  <li>
                    Realiza un ingreso de 50 euros* en concepto de aportación al capital social de Candela al siguiente número de cuenta:<br/>
                  FIARE : ES71 1550 0001 2500 1542 2629
                  </li>
                  <small>
                    * Recuerda que esta aportación es reembolsable, en los términos que recoge el artículo 13.e de los Estatutos de la cooperativa.
                  </small>
                  <li>Rellena este formulario, adjuntando el justificante de ingreso de la cuota.</li>
                </ol>
                <p>
                ¡Listo! Ya eres miembro de pleno derecho de Candela, así de fácil.
                </p>
              </Col>
            </Row>

            {/* FORM */}
            { mode === "natural" && <NaturalPartnerForm /> }
            { mode === "legal" && <LegalPartnerForm /> }
          </div>
        </Container>
      </section>

      <Footer />

    </div>
  )

}

export default Partner
